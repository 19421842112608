<div class="modal-overlay" *ngIf="showModal" role="dialog" aria-modal="true" aria-labelledby="modal-title"
  (click)="onOverlayClick($event)">
  <div class="modal" tabindex="-1" #modalContainer>
    <button class="close-modal-btn" #closeButton (click)="closeStarModal()">Close</button>
    <div class="modal-body">
      <ng-container *ngIf="hasApps(allAssessmentApps)">
        <div class="section">
          <h3 class="section-header">Star Assessments</h3>
          <div class="divider"></div>
          <ng-container *ngIf="hasApps(assessmentEnglishApps)">
            <h4 class="sub-section-header">English</h4>
            <div class="box-row">
              <button class="box" [tabindex]="0" *ngFor="let app of assessmentEnglishApps" (click)="launch(app)" (keyup.enter)="app">
                <div class="icon"><img src="../../assets/{{app.appSvg}}" alt="" /></div>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="hasApps(assessmentSpanishApps)">
            <h4 class="sub-section-header">Spanish</h4>
            <div class="box-row">
              <button class="box" [tabindex]="0" *ngFor="let app of assessmentSpanishApps" (click)="launch(app)" (keyup.enter)="app">
                <div class="icon"><img src="../../assets/{{app.appSvg}}" alt="" /></div>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="hasApps(assessmentCbmApps)">
            <h4 class="sub-section-header">CBM / Phonics</h4>
            <div class="box-row">
              <button class="box" [tabindex]="0" *ngFor="let app of assessmentCbmApps" (click)="launch(app)" (keyup.enter)="app">
                <div class="icon"><img src="../../assets/{{app.appSvg}}" alt="" /></div>
              </button>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="hasApps(saebrsApps)">
        <div class="section">
          <h3 class="section-header">SAEBRS</h3>
          <div class="divider"></div>
          <div class="box-row">
            <button class="box" [tabindex]="0" *ngFor="let app of saebrsApps" (click)="launch(app)" (keyup.enter)="app">
              <div class="icon"><img src="../../assets/{{app.appSvg}}" alt="" /></div>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
