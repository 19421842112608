import { Injectable } from '@angular/core';
import { StarTestService } from '../freckle-skill/star-test.service';
import { ARQuizService } from '../ar-quiz/ar-quiz.service';
import { FreckleSkillService } from '../freckle-skill/freckle-skill.service';
import { AccomplishmentCardConfig } from '../../shared/models/accomplishment-card-config.model';
import { AccomplishmentApps, AccomplishmentTitles, AccomplishmentType, Subjects } from '../../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class AccomplishmentsService {
  public accomplishments: AccomplishmentCardConfig[] | undefined = undefined;

  constructor(
    private starTestService: StarTestService,
    private arQuizService: ARQuizService,
    private freckleSkillService: FreckleSkillService
  ) { }

  // REVISIT: (with PM guidance) how to order accomplishments?
  public async getYearlyAccomplishments(): Promise<AccomplishmentCardConfig[]> {
    if (this.accomplishments === undefined) {
      this.accomplishments = [];
      const star = await this.getStarAccomplishments();
      const ar = await this.getArAccomplishments();
      const freckle = await this.getFreckleAccomplishments();
      this.accomplishments = [...star, ...ar, ...freckle];
    }

    return this.accomplishments;
  }

  public async getStarAccomplishments(): Promise<AccomplishmentCardConfig[]> {
    let acc = [];
    const starReadingTestData = await this.starTestService.getStarReadingTestData();
    const starMathTestData = await this.starTestService.getStarMathTestData();

    // First Star Reading Assessment
    if (starReadingTestData.length > 0) {
      const firstReadingTest =
        starReadingTestData.sort(
          (a, b) =>
            new Date(a.testDate).getTime() - new Date(b.testDate).getTime()
        )[0];

      acc.push({
        app: AccomplishmentApps.STAR,
        accomplishmentType: AccomplishmentType.StarAssessment,
        svgUrl: '../../assets/all-year-star-logo.svg',
        title: AccomplishmentTitles.StarAssessment.TITLE,
        subtitle: AccomplishmentTitles.StarAssessment.SUBTITLE,
        subject: Subjects.READING,
        month: new Date(firstReadingTest.testDate).getMonth()
      });
    }

    // First Star Math Assessment
    if (starMathTestData.length > 0) {
      const firstMathTest =
      starMathTestData.sort(
          (a, b) =>
            new Date(a.testDate).getTime() - new Date(b.testDate).getTime()
        )[0];

      acc.push({
        app: AccomplishmentApps.STAR,
        accomplishmentType: AccomplishmentType.StarAssessment,
        svgUrl: '../../assets/all-year-star-logo.svg',
        title: AccomplishmentTitles.StarAssessment.TITLE,
        subtitle: AccomplishmentTitles.StarAssessment.SUBTITLE,
        subject: Subjects.MATH,
        month: new Date(firstMathTest.testDate).getMonth()
      });
    }

    return acc;
  }

  public async getArAccomplishments(): Promise<AccomplishmentCardConfig[]> {
    let acc = [];
    const arQuizData = await this.arQuizService.getARQuizData();

    // AR Quiz Total
    if (arQuizData.length > 0) {
      acc.push({
        app: AccomplishmentApps.AR,
        accomplishmentType: AccomplishmentType.ARQuizzes,
        svgUrl: '../../assets/all-year-ar-logo.svg',
        title: AccomplishmentTitles.ARQuizzes.TITLE,
        subtitle: AccomplishmentTitles.ARQuizzes.SUBTITLE,
        quantity: arQuizData.length
      });
    }

    return acc;
  }

  public async getFreckleAccomplishments(): Promise<AccomplishmentCardConfig[]> {
    let acc = [];
    const freckleReadingData = await this.freckleSkillService.getReadingSkills();
    const freckleMathData = await this.freckleSkillService.getMathSkills();

    const readingTotalMins = Math.round(freckleReadingData.filter(skill => !!skill.minutesPracticed).reduce((sum, skill) => sum + skill.minutesPracticed!, 0));
    const mathTotalMins = Math.round(freckleMathData.filter(skill => !!skill.minutesPracticed).reduce((sum, skill) => sum + skill.minutesPracticed!, 0));

    // Freckle Reading Mins
    if (readingTotalMins) {
      acc.push({
        app: AccomplishmentApps.FRECKLE,
        accomplishmentType: AccomplishmentType.FrecklePractice,
        svgUrl: '../../assets/all-year-freckle-logo.svg',
        title: AccomplishmentTitles.FrecklePractice.TITLE,
        subtitle: AccomplishmentTitles.FrecklePractice.SUBTITLE,
        subject: Subjects.READING,
        quantity: readingTotalMins
      });
    }

    // Freckle Math Mins
    if (mathTotalMins) {
      acc.push({
        app: AccomplishmentApps.FRECKLE,
        accomplishmentType: AccomplishmentType.FrecklePractice,
        svgUrl: '../../assets/all-year-freckle-logo.svg',
        title: AccomplishmentTitles.FrecklePractice.TITLE,
        subtitle: AccomplishmentTitles.FrecklePractice.SUBTITLE,
        subject: Subjects.MATH,
        quantity: mathTotalMins
      });
    }

    return acc;
  }
}
