import { Injectable } from '@angular/core';
import { FreckleSkill } from '../../shared/models/freckle-skill';
import { FreckleSkillResult } from '../../shared/models/freckle-skill-result';

@Injectable({
  providedIn: 'root'
})
export class FreckleSkillProcessorService {

  constructor() { }

  processSkill(skill: FreckleSkill, result: FreckleSkillResult): void {
    const { correctAnswers, totalAnswers, minutesPracticed, displayName, lastPracticed } = skill;

    result.totalMinutes += minutesPracticed || 0;

    if (correctAnswers !== undefined && totalAnswers !== undefined) {
      const percentage = (correctAnswers / totalAnswers) * 100;

      const skillEntry = { displayName, lastPracticed };

      if (percentage < 50) {
        result.needHelp.skills.push(skillEntry);
      } else if (percentage < 80) {
        result.practicing.skills.push(skillEntry);
      } else {
        result.mastered.skills.push(skillEntry);
      }
    }
  }

  sortSkillsByLastPracticed(result: FreckleSkillResult): void {
    const sortByDateDescending = (a: { lastPracticed: string }, b: { lastPracticed: string }) =>
      new Date(b.lastPracticed).getTime() - new Date(a.lastPracticed).getTime();

    result.needHelp.skills.sort(sortByDateDescending);
    result.practicing.skills.sort(sortByDateDescending);
    result.mastered.skills.sort(sortByDateDescending);
  }

  convertMinutesToTimeString(minutes: number): string {
    const roundedMinutes = Math.round(minutes);
    const totalHours = Math.floor(roundedMinutes / 60);
    const remainingMinutes = roundedMinutes % 60;

    if (totalHours > 0 && remainingMinutes > 0) {
      return `${totalHours} hours and ${remainingMinutes} minutes`;
    } else if (totalHours > 0) {
      return `${totalHours} hours`;
    } else {
      return `${remainingMinutes} minutes`;
    }
  }
}
