<div class="ar-non-zero-state-container" *ngIf="arData.length > 0; else noResult">
    <div>
        <img src="../../assets/ar-corkboard-sticker.svg" alt="" class="ar-corner-image" />
        <h3 class="ar-non-zero-state-section-text"> Quizzes I Finished</h3>
    </div>
</div>
<ng-template #noResult>
    <div class="ar-zero-state-container">
        <img src="../../assets/ar-corkboard-sticker.svg" alt="A R Logo" class="ar-corner-image" />

        <h2 class="ar-zero-state-section-text" tabindex="0"> Quizzes you finish will show up here at the end of the
            week.</h2>
        <a class="ar-zero-state-click" href="{{launchUrl_AR}}" target="_blank">Find Quizzes in AR <i
                class="arrow right"></i></a>
    </div>
</ng-template>