import { Injectable } from '@angular/core';
import { FreckleSkill } from '../../shared/models/freckle-skill';
import { FreckleSkillResult } from '../../shared/models/freckle-skill-result';
import { firstValueFrom, Observable, of, take } from 'rxjs';
import { FreckleSkillProcessorService } from './freckle-skill-processor.service';

@Injectable({
  providedIn: 'root'
})
export class FreckleSkillService {

  constructor(private readingSkillProcessorService : FreckleSkillProcessorService) { }

  async getReadingSkills(): Promise<FreckleSkill[]> {
    const mockData = ([
      {
        "correctAnswers": 3,
        "displayName": "Explain Cause-and-Effect Relationships",
        "lastPracticed": "2024-11-15 20:31:05.502 Z",
        "minutesPracticed": 0.166667,
        "renSkillId": "2c11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 4
      },
      {
        "correctAnswers": 4,
        "displayName": "Determine Authorâ€™s Purpose in Informational Texts",
        "lastPracticed": "2024-11-27 17:48:52.082 Z",
        "minutesPracticed": 0.066667,
        "renSkillId": "8411f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 8
      },
      {
        "correctAnswers": 3,
        "displayName": "Explain How an Authorâ€™s Perspective Influences Informational Texts",
        "lastPracticed": "2024-11-27 17:46:05.995 Z",
        "minutesPracticed": 0.216667,
        "renSkillId": "a811f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 9
      },
      {
        "displayName": "Identify the Main Idea & Explain Key Details of Informational Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "renSkillId": "2e11f61e-e69f-e311-9503-005056801da1",
      },
      {
        "correctAnswers": 1,
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Identify the Main Idea & Explain Key Details of Informational Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.083333,
        "renSkillId": "2e11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "minutesPracticed": 0.016667,
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1",
      },
      {
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 3,
        "displayName": "Explain How an Authorâ€™s Perspective Influences Informational Texts",
        "lastPracticed": "2024-11-15 22:08:13.713 Z",
        "minutesPracticed": 0.066667,
        "renSkillId": "a811f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 9
      },
      {
        "displayName": "Use Details from Texts to Determine Theme",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "renSkillId": "2b10f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Use Details from Texts to Determine Theme",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.05,
        "renSkillId": "2b10f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.016667,
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Summarize the Main Idea & Key Details of Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "renSkillId": "2f11f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.016667,
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Summarize Main Events & Key Details of Stories or Dramas",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.116667,
        "renSkillId": "2610f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Identify the Main Idea & Explain Key Details of Informational Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.05,
        "renSkillId": "2e11f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Summarize the Main Idea & Key Details of Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "renSkillId": "2f11f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Use Details from Texts to Determine Theme",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.05,
        "renSkillId": "2b10f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 4,
        "displayName": "Determine Authorâ€™s Purpose in Informational Texts",
        "lastPracticed": "2024-11-15 22:10:08.414 Z",
        "minutesPracticed": 0.066667,
        "renSkillId": "8411f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 8
      },
      {
        "correctAnswers": 3,
        "displayName": "Ask & Answer Questions About Key Details in Informational Texts",
        "lastPracticed": "2024-11-27 17:40:09.584 Z",
        "minutesPracticed": 0.15,
        "renSkillId": "b96c97fa-10c8-4f97-9565-80e3c5e05987",
        "totalAnswers": 13
      },
      {
        "correctAnswers": 1,
        "displayName": "Summarize Main Events & Key Details of Stories or Dramas",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.083333,
        "renSkillId": "2610f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Identify the Main Idea & Explain Key Details of Informational Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.066667,
        "renSkillId": "2e11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.066667,
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.05,
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 1.383333,
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.083333,
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Summarize Main Events & Key Details of Stories or Dramas",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.05,
        "renSkillId": "2610f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Explain Cause-and-Effect Relationships",
        "lastPracticed": "2024-11-15 20:31:05.502 Z",
        "renSkillId": "2c11f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Summarize the Main Idea & Key Details of Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.55,
        "renSkillId": "2f11f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.016667,
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.133333,
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.016667,
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.05,
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.016667,
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.116667,
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Capitalize First Word in Sentence",
        "lastPracticed": "2024-11-15 22:03:11.744 Z",
        "minutesPracticed": 1.416667,
        "renSkillId": "hftdojap"
      },
      {
        "correctAnswers": 3,
        "displayName": "Explain Cause-and-Effect Relationships",
        "lastPracticed": "2024-11-15 20:31:05.502 Z",
        "minutesPracticed": 2.333333,
        "renSkillId": "2c11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 4
      },
      {
        "displayName": "Identify the Main Idea & Explain Key Details of Informational Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.083333,
        "renSkillId": "2e11f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Summarize the Main Idea & Key Details of Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.016667,
        "renSkillId": "2f11f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Summarize Main Events & Key Details of Stories or Dramas",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 1.166667,
        "renSkillId": "2610f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Summarize Main Events & Key Details of Stories or Dramas",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "renSkillId": "2610f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Identify the Main Idea & Explain Key Details of Informational Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "renSkillId": "2e11f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Use Details from Texts to Determine Theme",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "renSkillId": "2b10f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "minutesPracticed": 0.016667,
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 3,
        "displayName": "Ask & Answer Questions About Key Details in Informational Texts",
        "lastPracticed": "2024-11-27 17:40:47.194 Z",
        "minutesPracticed": 0.466667,
        "renSkillId": "b96c97fa-10c8-4f97-9565-80e3c5e05987",
        "totalAnswers": 13
      },
      {
        "correctAnswers": 1,
        "displayName": "Use Details from Texts to Determine Theme",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "2b10f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Summarize the Main Idea & Key Details of Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.066667,
        "renSkillId": "2f11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.016667,
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.083333,
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.35,
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.133333,
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "minutesPracticed": 0.016667,
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 4,
        "displayName": "Determine Authorâ€™s Purpose in Informational Texts",
        "lastPracticed": "2024-11-15 22:09:45.994 Z",
        "minutesPracticed": 0.083333,
        "renSkillId": "8411f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 8
      },
      {
        "correctAnswers": 4,
        "displayName": "Explain Cause-and-Effect Relationships",
        "lastPracticed": "2024-11-15 20:31:05.502 Z",
        "minutesPracticed": 0.183333,
        "renSkillId": "2c11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 4
      },
      {
        "displayName": "Explain Cause-and-Effect Relationships",
        "lastPracticed": "2024-11-15 20:31:05.502 Z",
        "renSkillId": "2c11f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Explain Cause-and-Effect Relationships",
        "lastPracticed": "2024-11-15 20:31:05.502 Z",
        "renSkillId": "2c11f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 4,
        "displayName": "Explain Cause-and-Effect Relationships",
        "lastPracticed": "2024-11-15 20:31:05.502 Z",
        "minutesPracticed": 0.166667,
        "renSkillId": "2c11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 4
      },
      {
        "correctAnswers": 4,
        "displayName": "Ask & Answer Questions About Informational Textsâ€™ Key Details",
        "lastPracticed": "2024-11-14 13:34:02.342 Z",
        "minutesPracticed": 1.466667,
        "renSkillId": "1f11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 4
      },
      {
        "correctAnswers": 1,
        "displayName": "Identify the Main Idea & Explain Key Details of Informational Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.916667,
        "renSkillId": "2e11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Use Details from Texts to Determine Theme",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.083333,
        "renSkillId": "2b10f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Summarize Main Events & Key Details of Stories or Dramas",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "renSkillId": "2610f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.116667,
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Summarize the Main Idea & Key Details of Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.05,
        "renSkillId": "2f11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Use Details from Texts to Determine Theme",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.866667,
        "renSkillId": "2b10f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Summarize Main Events & Key Details of Stories or Dramas",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.016667,
        "renSkillId": "2610f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.05,
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.033333,
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.05,
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1"
      },
      {
        "correctAnswers": 1,
        "displayName": "Summarize the Main Idea & Key Details of Texts",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.05,
        "renSkillId": "2f11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "minutesPracticed": 0.016667,
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Use Details & Evidence to Make Inferences",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "2a10f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Make Inferences When Reading Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Make Inferences Based On Details Found in Stories or Plays",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1"
      },
      {
        "displayName": "Explain the Explicit Meaning of Informational Texts",
        "lastPracticed": "2024-11-18 18:20:02.448 Z",
        "renSkillId": "3011f61e-e69f-e311-9503-005056801da1"
      },
    ]);

    let readingSkills = await firstValueFrom(
      of(mockData).pipe(
        take(1)
      )
    );

    return readingSkills;
  }

  async getMathSkills():  Promise<FreckleSkill[]> {
   const mockData = ([
      {
        "correctAnswers": 4,
        "displayName": "Explain Cause-and-Effect Relationships in Maths",
        "lastPracticed": "2024-11-15 20:31:05.502 Z",
        "minutesPracticed": 0.166667,
        "renSkillId": "2c11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 4
      },
      {
        "correctAnswers": 1,
        "displayName": "Make Inferences When Reading Informational in Maths",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.333333,
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 1,
        "displayName": "Identify the Main Idea & Explain Key Details of Informational in Maths",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.883333,
        "renSkillId": "2e11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 1
      },
      {
        "correctAnswers": 5,
        "displayName": "Determine Authorâ€™s Purpose in Informational in Maths",
        "lastPracticed": "2024-11-27 17:48:52.082 Z",
        "minutesPracticed": 0.666667,
        "renSkillId": "8411f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 8
      },
      {
        "correctAnswers": 7,
        "displayName": "Explain How an Authorâ€™s Perspective Influences Informational in Maths",
        "lastPracticed": "2024-11-27 17:46:05.995 Z",
        "minutesPracticed": 0.216667,
        "renSkillId": "a811f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 9
      },


      {
        "correctAnswers": 6,
        "displayName": "Explain How an Authorâ€™s Perspective Influences Informational in Maths",
        "lastPracticed": "2024-11-15 22:08:13.713 Z",
        "minutesPracticed": 0.666667,
        "renSkillId": "a811f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 9
      },

      {
        "correctAnswers": 3,
        "displayName": "Use Details from Texts to Determine in Maths",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.15,
        "renSkillId": "2b10f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 10
      },{
        "correctAnswers": 4,
        "displayName": "Make Inferences When Reading Informational in Maths",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.333333,
        "renSkillId": "3111f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 10
      },
      {
        "correctAnswers": 2,
        "displayName": "Summarize Main Events & Key Details of Stories or in Maths",
        "lastPracticed": "2024-11-14 13:21:00.191 Z",
        "minutesPracticed": 0.116667,
        "renSkillId": "2610f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 10
      },
      {
        "correctAnswers": 5,
        "displayName": "Make Inferences Based On Details Found in Stories or in Maths",
        "lastPracticed": "2024-11-14 13:20:37.254 Z",
        "minutesPracticed": 0.33333,
        "renSkillId": "2910f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 10
      },
    ]);

    let mathSkills = await firstValueFrom(
      of(mockData).pipe(
        take(1)
      )
    );

    return mathSkills;
  }

  processFreckleSkills(freckleSkills: FreckleSkill[]): FreckleSkillResult {
    const result = new FreckleSkillResult();

    freckleSkills.forEach(skill => this.readingSkillProcessorService.processSkill(skill, result));

    this.readingSkillProcessorService.sortSkillsByLastPracticed(result);

    result.timeSpent = this.readingSkillProcessorService.convertMinutesToTimeString(result.totalMinutes);

    result.hasLargeGroup = [
      result.needHelp.skills,
      result.practicing.skills,
      result.mastered.skills
    ].some(group => group.length > 3);

    return result;
  }
}
