import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { StarTestService } from '../../../services/freckle-skill/star-test.service';
import { DateSuffixPipe } from '../../pipes/date-suffix.pipe';
import { StarTestResponse } from '../../../services/freckle-skill/star-test.response';

@Component({
  selector: 'app-star-test',
  standalone: true,
  imports: [CommonModule, DateSuffixPipe],
  templateUrl: './star-test.component.html',
  styleUrl: './star-test.component.scss',
})
export class StarTestComponent {
  starTestService = inject(StarTestService);

  @Input() starTestData: StarTestResponse[] = [];
  @Input() starHeaderText: string = '';

  showMore: boolean = false;
  latestTestData: StarTestResponse = {
    testDate: '',
    scaledScore: 0,
    skills: [],
  };

  ngOnChanges() {
    this.latestTestData = this.getStarTestData();
  }

  getStarTestData() {
    return this.starTestData.length
      ? this.starTestData.sort(
          (a, b) =>
            new Date(b.testDate).getTime() - new Date(a.testDate).getTime()
        )[0]
      : this.latestTestData;
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
  }
}
