import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FreckleSkillResult } from '../../models/freckle-skill-result';
import { CommonModule } from '@angular/common';
import { FocusTrapService } from '../../../services/focus-trap/focus-trap.service';

@Component({
  selector: 'freckle-skill-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './freckle-skill-modal.component.html',
  styleUrl: './freckle-skill-modal.component.scss'
})
export class FreckleSkillModalComponent {
  @Output() close = new EventEmitter<void>();
  @Input() showModal = false;
  @Input() freckleSkillResult: FreckleSkillResult | null = null;

  constructor(private focusTrapService: FocusTrapService) { }

  @ViewChild('modalContainer', { static: false }) modalContainer!: ElementRef;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showModal'] && changes['showModal'].currentValue) {
      setTimeout(() => {
        this.focusTrapService.trapFocus(this.modalContainer.nativeElement);
        this.modalContainer.nativeElement.querySelector('button').focus();
      }, 0);
    }

    document.addEventListener('keydown', this.handleEscapeKey.bind(this));
  }

  handleEscapeKey(event: KeyboardEvent): void {
    if (event.key === 'Escape' && this.showModal) {
      this.closeFreckleSkillModal();
    }
  }

  onOverlayClick(event: MouseEvent) {
    this.closeFreckleSkillModal();
  }

  ngOnDestroy() {
    if (this.modalContainer) {
      this.focusTrapService.releaseFocus(this.modalContainer.nativeElement);
    }
    document.removeEventListener('keydown', this.handleEscapeKey.bind(this));
  }

  closeFreckleSkillModal() {
    this.close.emit();
  }
}
