import { Injectable } from '@angular/core';
import { AppNavItem } from '../../shared/app-nav-item-model';
import { ProductAppCodes } from '../../shared/models/product-info.model';

@Injectable({
  providedIn: 'root'
})
export class AvailableAppsService {

  constructor() { }

  public getAllAvailableApps() {
    let items: AppNavItem[] = [];
    items.push({
      appCode: ProductAppCodes.StarReading,
      displayName: "Star Reading"
     });
     items.push({
      appCode: ProductAppCodes.StarMath,
      displayName: "Star Reading"
     });
     items.push({
      appCode: ProductAppCodes.StarMath,
      displayName: "Star Math"
     });
     items.push({
      appCode: ProductAppCodes.StarMath,
      displayName: "Star Math"
     });
     items.push({
      appCode: ProductAppCodes.StarEarlyLiteracy,
      displayName: "Star Early Literacy"
     });
     items.push({
      appCode: ProductAppCodes.StarReadingSpanish,
      displayName: "Star Reading Spanish"
     });
     items.push({
      appCode: ProductAppCodes.StarMathSpanish,
      displayName: "Star Math Spanish"
     });
     items.push({
      appCode: ProductAppCodes.StarEarlyLiteracySpanish,
      displayName: "Star Early Literacy Spanish"
     });
     items.push({
      appCode: ProductAppCodes.StarCbm,
      displayName: "Star CBM"
     });
     items.push({
      appCode: ProductAppCodes.FreckleMath,
      displayName: "Freckle Math"
     });
     items.push({
      appCode: ProductAppCodes.Myon,
      displayName: "Myon"
     });
     items.push({
      appCode: ProductAppCodes.Lalilo,
      displayName: "Lalilo"
     });
     items.push({
      appCode: ProductAppCodes.StarPhonics,
      displayName: "Star Phonics"
     });
     items.push({
      appCode: ProductAppCodes.Saebrs,
      displayName: "Saebrs"
     });

     return items;
  }
}
