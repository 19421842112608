<div class="year-container">
    <div class="year-sub-container">
        <div class="year-content">
            <img src="../../../assets/my-accomplishments-heading.svg" alt="An image representing My Accomplishments"
                class="accomplishment-image" role="img" aria-labelledby="year-text" />
            <div *ngIf="schoolYear" class="text-box">
                <h2 class="text">{{schoolYear!.title}}</h2>
            </div>

            <!-- TODO: (LB-549) if accomplishments, show grid; else, zero state -->
            <div class="cards-container">
              <div class="cards-subcontainer">
                <app-accomplishments-grid [cardConfigs]="cardConfigs"></app-accomplishments-grid>
              </div>
            </div>
        </div>
    </div>
</div>
