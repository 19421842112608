<div class="container" id="skip-nav" tabindex="-1">
    <app-pop-up *ngIf="!loading && licensedApps!.length > 0" [data]="popupData" [title]="popupTitle"></app-pop-up>
</div>
<div id="skip-nav" tabindex="-1">
    <div *ngIf="!loading">
        <div *ngIf="licensedApps!.length > 0; else noLicensedApps">
            <div class="container align">
                <div class="rounded-strip">
                    <button class="btn" [ngClass]="{ 'active': activeIndex === 0 }" (click)="toggleActive(0)"
                        [attr.aria-current]="activeIndex === 0 ? 'page' : null">My Apps</button>
                    <button class="btn" [ngClass]="{ 'active': activeIndex === 1 }" (click)="toggleActive(1)"
                        [attr.aria-current]="activeIndex === 1 ? 'page' : null">
                        My Learning Journey</button>
                </div>
            </div>
            <router-outlet></router-outlet>
        </div>
    </div>
    <ng-template #noLicensedApps>
        <zero-state-license></zero-state-license>
    </ng-template>
</div>
