import { FreckleSkillGroupName } from "../../constants/constants";
import { FreckleSkillGroup } from "./freckle-skill-group";

export class FreckleSkillResult {
    needHelp: FreckleSkillGroup;
    practicing: FreckleSkillGroup;
    mastered: FreckleSkillGroup;
    timeSpent: string;
    totalMinutes: number;
    hasLargeGroup: boolean;
  
    constructor() {
      this.mastered = { groupName: FreckleSkillGroupName.good, skills: [] };
      this.practicing = { groupName: FreckleSkillGroupName.practice, skills: [] };
      this.needHelp = { groupName: FreckleSkillGroupName.help, skills: [] };   
      this.hasLargeGroup = false;
      this.totalMinutes = 0;
      this.timeSpent = '';
    }
}