import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineMonth } from '../shared/models/timeline-month.model';

@Component({
  selector: 'app-timeline',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.scss'
})
export class TimelineComponent implements OnInit {
  @Input() allYear?: TimelineMonth;
  @Input() timelineMonths: TimelineMonth[] = [];
  @Input() selectedMonth: TimelineMonth = {} as TimelineMonth;
  @Output() monthChangeEvent = new EventEmitter<TimelineMonth>();

  // public
  currentMonth: TimelineMonth = {} as TimelineMonth;
  lastMonth: TimelineMonth = {} as TimelineMonth;

  constructor() { }

  ngOnInit(): void {
    this.currentMonth = this.timelineMonths.find(month => month.current)!;
    this.lastMonth = this.timelineMonths.at(-1)!;
  }

  public onMonthChange(selection: TimelineMonth) {
    this.monthChangeEvent.emit(selection);
  }
}
