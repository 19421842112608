<nav class="timeline-container" aria-label="Left nav">
  <ul>
    <li *ngIf="allYear" class="timeline-option all-year">
      <button class="timeline-option--button enabled" [ngClass]="{'selected': allYear.order === selectedMonth!.order}" (click)="onMonthChange(allYear)">{{allYear.label}}</button>
    </li>
    <li *ngFor="let month of timelineMonths" class="timeline-option">
      <button *ngIf="month.enabled" class="timeline-option--button enabled" [ngClass]="{'selected': month.order === selectedMonth!.order}" [attr.aria-current]="month.order === selectedMonth!.order" (click)="onMonthChange(month)">{{month.label}}
        <span class="glyph-circle2 radio-glyph fill" [ngClass]="{'selected': month.order === selectedMonth!.order}"></span><span class="glyph-circle radio-glyph outline" [ngClass]="{'selected': month.order === selectedMonth!.order}"></span>
      </button>
      <div *ngIf="!month.enabled" class="timeline-option--button disabled">
        <span class="glyph-circle2 radio-glyph fill"></span><span class="glyph-circle radio-glyph outline"></span>
      </div>
      <div *ngIf="month.order < lastMonth.order" class="dashed-line" [ngClass]="{'enabled': month.order < currentMonth.order}"></div>
    </li>
  </ul>
</nav>
