import { Inject, Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { DOCUMENT } from '@angular/common';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FreckleDataService {

  constructor(
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  getMockData(): Observable<any[]> {
    const mockData = [
      {
        "correctAnswers": 3,
        "displayName": "Explain Cause-and-Effect Relationships",
        "lastPracticed": "2024-11-15 20:31:05.502 Z",
        "minutesPracticed": 0.166667,
        "renSkillId": "2c11f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 4
    },
    {
        "correctAnswers": 4,
        "displayName": "Determine Authorâ€™s Purpose in Informational Texts",
        "lastPracticed": "2024-11-27 17:48:52.082 Z",
        "minutesPracticed": 0.066667,
        "renSkillId": "8411f61e-e69f-e311-9503-005056801da1",
        "totalAnswers": 8
    }
    ];

    return of(mockData);
  }

  getEmptyMockData(): Observable<any[]> {
    return of([]);
  }


}
