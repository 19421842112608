import { Injectable } from '@angular/core';
import { firstValueFrom, of, take } from 'rxjs';
import { ARQuizResponse } from './ar-quiz.response';

@Injectable({
  providedIn: 'root'
})
export class ARQuizService {

  constructor() { }

  async getARQuizData(): Promise<ARQuizResponse[]> {
    const mockData = [
      {
        "title": "The Cat in the Hat",
        "imageUrl": "/path/to/image",
        "completedDate": "2024-10-15 20:31:05.502 Z"
      },
      {
        "title": "A Series of Unfortunate Events",
        "imageUrl": "/path/to/image",
        "completedDate": "2024-10-30 20:31:05.502 Z"
      },
      {
        "title": "The Lorax",
        "imageUrl": "/path/to/image",
        "completedDate": "2024-11-01 20:31:05.502 Z"
      },
      {
        "title": "Walk Two Moons",
        "imageUrl": "/path/to/image",
        "completedDate": "2024-11-10 20:31:05.502 Z"
      },
      {
        "title": "Goodnight Moon",
        "imageUrl": "/path/to/image",
        "completedDate": "2024-11-12 20:31:05.502 Z"
      },
      {
        "title": "The Very Hungry Caterpillar",
        "imageUrl": "/path/to/image",
        "completedDate": "2024-11-13 20:31:05.502 Z"
      }
    ];

    let arQuizzes = await firstValueFrom(
      of(mockData).pipe(
        take(1)
      )
    );

    return arQuizzes;
  }
}
