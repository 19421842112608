import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyAppsComponent } from '../my-apps/my-apps.component';
import { MyLearningComponent } from '../my-learning/my-learning.component';
import { PopUpComponent } from '../shared/pop-up/pop-up.component';
import { ApplicationService } from '../services/application/application.service';
import { AppNavItem } from '../shared/app-nav-item-model';
import { ZeroStateLicenseComponent } from '../zero-state-license/zero-state-license.component';
import { PopUpService } from '../shared/pop-up/pop-up.service';
import { Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ZeroStateLicenseComponent, PopUpComponent],
  providers: [PopUpService],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent {
  activeIndex: number = 0;
  popupTitle = 'Check out your Progress!';
  popupData = { previousWeekData: 'Data from the previous week' };
  licensedApps: AppNavItem[] = [];
  loading = true;

  constructor(
    private applicationService: ApplicationService,
    private popUpService: PopUpService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.licensedApps = await this.applicationService.getLicensedApplications();
    this.loading = false;
    this.bindToggle();
  }

  bindToggle(){
    this.activeIndex = this.router.url.includes('reading') ? 1 : 0;
  }

  toggleActive(index: number) {
    this.popUpService.closePopup();
    this.popUpService.toggleActive(index);
    this.activeIndex = index;
    if (index == 0) {
      this.router.navigate(['/myapps']);
    }
    else {
      this.router.navigate(['/mylearningjourney/reading']);
    }
  }
}
