export const Language = {
  EnUs: 'en-US',
  EnGb: 'en-GB',
} as const;

export enum AppSection {
  Practice = 'practice',
  Assessment = 'assessment',
  Saebrs = 'saebrs',
  StatePortal = 'state',
  Unknown = 'unknown'
}

export enum AppSubSection {
  English = 'english',
  Spanish = 'spanish',
  CBM = 'cbm',
  None = 'none'
}

export enum TakenAt {
  School = 'SCHOOL',
  Away = 'AWAY',
  NotApplicable = 'NA'
}

export enum HelpId {
  HomePageEnUs = 'en-us-help-setup-22521',
  HomePageSuffix = '-help-setup-22521'
}

export enum ErrorModalTitles {
  Freckle = 'Freckle',
  Lalilo = 'Lalilo'
}

export type ErrorModalTypes = 'freckle' | 'lalilo';

export const ErrorModalNames: readonly ErrorModalTypes[] = ['freckle', 'lalilo'];

export const ErrorModalAppTags = {
  lalilo: 'APPS_LALILO',
  freckle: 'APPS_FRECKLE'
}

export enum LaunchErrorMessages {
  GenericFailure = 'L10N.LAUNCHERROR.GENERIC',
  FailedCapacityCheck = 'L10N.LAUNCHERROR.CAPACITY',
  UnknownError = 'L10N.LAUNCHERROR.UNKNOWN'
}

export enum LaunchRestrictionModalTypes {
  GenericFailure = 'genericFailure',
  FailedCapacityCheck = 'failedCapacityCheck'
}

export const StarStudentDiscoveryToken = '$star-student-discovery$';
export const StarCBMToken = '$star-cbm$';
export const StatePortalFloridaToken = '$state-portal-florida$';

export const ArArticleQuizTypes: readonly string[] = [
  'ASNTYP_AR360ARQ',
  'ASNTYP_AR360ASPQ',
  'ASNTYP_AR360ARA'
];

export const ArQuizTypes: readonly string[] = [
  'ASNTYP_ARRP',
  'ASNTYP_ARLS',
  'ASNTYP_AROR',
  'ASNTYP_ARVP'
];

export const Months: readonly string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const FreckleSkillGroupName = {
  good : 'I’m really good at',
  practice: 'I’m practicing',
  help: 'I might need help on'
}

export const AccomplishmentApps = {
  STAR: 'Star',
  AR: 'AR',
  FRECKLE: 'Freckle'
};

export enum AccomplishmentType {
  StarAssessment = 'STAR_ASSESSMENT',
  ARQuizzes = 'AR_QUIZZES',
  FrecklePractice = 'FRECKLE_PRACTICE',
  ImprovedSS = 'IMPROVED_SS'
}

export const Subjects = {
  READING: 'Reading',
  MATH: 'Math'
}

// REVISIT: move (ideally to i18n files) or remove me if using fun SVGs for cards
export const AccomplishmentTitles = {
  StarAssessment: {
    TITLE: 'I finished my first Star {subject} Assessment!',
    SUBTITLE: 'in {month}'
  },
  ARQuizzes: {
    TITLE:   'I finished {quantity} Quizzes so far this year!',
    SUBTITLE: ''
  },
  FrecklePractice: {
    TITLE: 'I practiced {quantity} {subject} minutes in Freckle so far this year!',
    SUBTITLE: ''
  },
  ImprovedSS: {
    TITLE: 'You\'ve improved your SS this year',
    SUBTITLE: 'since {month}'
  }
}
