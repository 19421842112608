<div class="text-container">
    <h1 class="text-group"><span class="text-item heading-text">Welcome,</span>
        <span class="name-text">{{userName}}</span>
        <span class="heading-text">!</span>
        <span class="text-item heading-text">Let's</span>
        <span class="text-item heading-text">learn together.</span>
    </h1>
</div>

<div class="my-app-parnet-container">
    <div class="my-app-child-container">
        <div class="section">
            <h2 *ngIf="hasApps(practiceApps)" class="section-header">Practice</h2>
            <ul class="box-row" id="practice-box">
                <li *ngFor="let app of practiceApps">
                    <a class="box" 
                        [ngStyle]="{ '--hover-border-color': app.color }" 
                        [tabindex]="0"
                        (click)="navigate(app)"
                        (keyup.enter)="navigate(app)"
                        >
                        <div class="icon"><img src="../../assets/{{app.appSvg}}" alt="" /></div>
                        <div class="text">{{app.appTitle}}</div>
                    </a>
                </li>
            </ul>
            
            <div class="divider"></div>
        </div>

        <div class="section">
            <h2 *ngIf="hasApps(allAssessmentApps)" class="section-header">Star Assessments</h2>
            <div class="box-row">
                <button (click)="openStarModal()" [tabindex]="0" class="box"
                    [ngStyle]="{ '--hover-border-color': '#BF8C00' }">
                    <div class="icon"><img src="../../assets/star-tile.svg" alt=""></div>
                    <div class="text">Star Assessments</div>
                </button>
            </div>
        </div>
    </div>
    <star-apps-modal (close)="closeStarModal()" [showModal]="showModal" [allAssessmentApps]="allAssessmentApps"
        [assessmentEnglishApps]="assessmentEnglishApps" [assessmentSpanishApps]="assessmentSpanishApps"
        [assessmentCbmApps]="assessmentCbmApps" [saebrsApps]="saebrsApps">
    </star-apps-modal>
</div>