<div class="star-test-reading-box">
    <img src="../../assets/star-corkboard-sticker.svg" alt="Reading corner image" class="reading-corner-image" />
    <div class="reading-header-container">
        <h2 class="reading-section-header">{{starHeaderText}}
            {{ latestTestData.testDate | date:"MMMM" }} {{ latestTestData.testDate | date:"d" | dateSuffix }}.
        </h2>
        <button class="show-more" (click)="toggleShowMore()" [attr.aria-expanded]="showMore">
            {{ showMore ? 'Show Less' : 'Show More' }}
            <span [ngClass]="{'glyph-chevron-expanded': !showMore, 'glyph-chevron-collapsed': showMore }"></span>
        </button>
    </div>
    <div class="reading-content-score-skills" *ngIf="showMore">
        <h2 class="score-title">My Scaled Score was <span class="score">{{latestTestData.scaledScore}}</span>.</h2>
        <button class="show-less" (click)="toggleShowMore()" [attr.aria-expanded]="showMore">
            Show Less <span class="glyph-chevron-collapsed"></span>
        </button>
        <div class="divider"></div>
        <div class="reading-content-list">
            <div class="reading-header-row">
                <img src="../../assets/appropriate-level2.svg" alt="" class="reading-practise-image" />
                <h3 tabindex="0" class="reading-list-header">Important skills I could learn</h3>
            </div>
            <ul class="reading-bullet-list">
                <li *ngFor="let skill of latestTestData?.skills">{{ skill?.shortName }}</li>
            </ul>
        </div>
    </div>
</div>
