import { CommonModule } from '@angular/common';
import { ReadingComponent } from '../reading/reading.component';
import { Component, OnInit } from '@angular/core';
import { LearningJourneyService } from '../services/learning-journey/learning-journey.service';
import { SchoolYear } from '../shared/models/school-year.model';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Months } from '../constants/constants';
import { TimelineMonth } from '../shared/models/timeline-month.model';
import { TimelineComponent } from '../timeline/timeline.component';
import { TimelinePickerComponent } from '../pickers/timeline-picker/timeline-picker.component';
import { ClockService } from '../services/clock/clock.service';

@Component({
  selector: 'my-learning',
  standalone: true,
  imports: [CommonModule, RouterOutlet, TimelineComponent, TimelinePickerComponent],
  templateUrl: './my-learning.component.html',
  styleUrl: './my-learning.component.scss'
})
export class MyLearningComponent implements OnInit {
  // public
  public now: Date | undefined = undefined;
  public schoolYear: SchoolYear | undefined = undefined;
  public schoolYearMonths: TimelineMonth[] = [];
  public selectedMonth: TimelineMonth = {} as TimelineMonth;
  public showTopNav: boolean = true;
  public previousRoute: string = "";

  activeIndex: number = 0;
  loadReading: boolean = true;
  readingImage: string = "../../assets/reading-icon-active.svg";
  mathImage: string = "../../assets/math-icon-default.svg";

  // private
  private currentMonth: TimelineMonth = {} as TimelineMonth;
  allYear!: TimelineMonth;

  constructor(
    private learningJourneyService: LearningJourneyService,
    private clockService: ClockService,
    private router: Router
  ) {
    this.allYear = {
      order: -1,
      calendarIndex: -1,
      year: clockService.now().getFullYear(),
      label: "All Year", // i18n
      enabled: true
    } as TimelineMonth;
  }

  async ngOnInit() {
    await this.initTimeline();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousRoute = this.router.url;
        this.showTopNav = !(this.previousRoute === '/mylearningjourney/all-year');
      }
    });

    this.bindToggle();
  }

  private async initTimeline() {
    this.now = this.clockService.now();
    this.schoolYear = await this.learningJourneyService.getCurrentSchoolYear();

    const start = new Date(this.schoolYear!.startDate);
    const end = new Date(this.schoolYear!.endDate);
    this.schoolYearMonths = MyLearningComponent.getTimelineMonths(
      start,
      end
    );
    this.processCurrentAndEnabledMonths(this.schoolYearMonths,
      start,
      end
    );

    // check for stored selection in LearningJourneyService
    // if there is none, default to current month
    if (this.learningJourneyService.selectedMonth) {
      this.selectedMonth = this.learningJourneyService.selectedMonth;
    } else {
      this.selectedMonth = this.currentMonth;
      this.learningJourneyService.selectedMonth = this.selectedMonth;
    }
  }

  /**
   * Produce a list of months given a start and end date
   * @param startDate
   * @param endDate
   * @returns
   */
  public static getTimelineMonths(startDate: Date, endDate: Date): TimelineMonth[] {
    let timeline: TimelineMonth[] = [];

    let startMonth = startDate.getMonth();
    let endMonth = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth();

    for (let i = 0; startMonth <= endMonth; i++, startMonth++) {
      let calendarIndex = startMonth % 12;
      let year = startMonth > 11 ? endDate.getFullYear() : startDate.getFullYear();
      let month = {
        order: i,
        calendarIndex: calendarIndex + 1, // 1-based index (Jan=1, Dec=12)
        year: year,
        label: Months[calendarIndex]
      } as TimelineMonth;
      timeline.push(month);
    }
    return timeline;
  }

  /**
   * Mutate and decorate months with curent and enabled
   * @param months
   * @param startDate
   * @param endDate
   */
  private processCurrentAndEnabledMonths(months: TimelineMonth[], startDate: Date, endDate: Date): void {
    let startMonth = startDate.getMonth();
    let endMonth = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth();
    let currentMonth = endMonth;
    for (let i = 0; startMonth <= endMonth; i++, startMonth++) {
      const month = months[i];
      let calendarIndex = startMonth % 12;
      let year = startMonth > 11 ? endDate.getFullYear() : startDate.getFullYear();
      if (this.now!.getMonth() == calendarIndex && this.now!.getFullYear() == year) {
        month.current = true;
        currentMonth = startMonth;
        this.currentMonth = month;
      }
      month.enabled = startMonth <= currentMonth ? true : false;
    }
  }

  bindToggle() {
    this.activeIndex = this.router.url.includes('reading') ? 0 : 1;
  }

  toggleActive(index: number) {
    this.activeIndex = index;
    if (index == 0) {
      this.readingImage = "../../assets/reading-icon-active.svg";
      this.mathImage = "../../assets/math-icon-default.svg";
      this.loadReading = true;
      this.router.navigate(['/mylearningjourney/reading'], { queryParams: { month: 1 } });
    }
    else {
      this.mathImage = "../../assets/math-icon-active.svg";
      this.readingImage = "../../assets/reading-icon-default.svg";
      this.loadReading = false;
      this.router.navigate(['/mylearningjourney/math'], { queryParams: { month: 1 } });
    }
  }

  onMonthChangeEvent(month: any) {
    this.selectedMonth = month;
    this.learningJourneyService.selectedMonth = month;
    this.navigate(month);
  }

  navigate(month: any) {
    if (month.label === 'All Year') {
      this.router.navigate(['mylearningjourney/all-year']);
    }
    else if (this.checkSubstringInPreviousRoute("math")) {
      this.router.navigate(['mylearningjourney/math']);
      this.activeIndex = 1;
    }
    else if (this.checkSubstringInPreviousRoute("reading")) {
      this.router.navigate(['mylearningjourney/reading']);
      this.activeIndex = 0;
    }
    else {
      this.router.navigate(['mylearningjourney/reading']);
      this.activeIndex = 0;
    }
  }

  checkSubstringInPreviousRoute(substring: string): boolean {
    return this.previousRoute.includes(substring);
  }
}



