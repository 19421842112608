import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccomplishmentCardConfig } from '../../../shared/models/accomplishment-card-config.model';
import { AccomplishmentCardComponent } from '../accomplishment-card/accomplishment-card.component';

@Component({
  selector: 'app-accomplishments-grid',
  standalone: true,
  imports: [CommonModule, AccomplishmentCardComponent],
  templateUrl: './accomplishments-grid.component.html',
  styleUrl: './accomplishments-grid.component.scss'
})
export class AccomplishmentsGridComponent {
  @Input() cardConfigs: AccomplishmentCardConfig[] = [];

  constructor() {}
}
