import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppInfo } from '../services/app-lookup.service';
import { FocusTrapService } from '../services/focus-trap/focus-trap.service';
import { LaunchControlService } from '../services/launch-control/launch-control.service';

@Component({
  selector: 'star-apps-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './star-apps-modal.component.html',
  styleUrl: './star-apps-modal.component.scss'
})
export class StarAppsModalComponent {
  @Output() close = new EventEmitter<void>();
  @Input() showModal = false;
  @Input() allAssessmentApps: AppInfo[] = [];
  @Input() assessmentEnglishApps: AppInfo[] = [];
  @Input() assessmentSpanishApps: AppInfo[] = [];
  @Input() assessmentCbmApps: AppInfo[] = [];
  @Input() saebrsApps: AppInfo[] = [];

  constructor(
    private focusTrapService: FocusTrapService, 
    private launchControlService: LaunchControlService) { }

  @ViewChild('modalContainer', { static: false }) modalContainer!: ElementRef;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showModal'] && changes['showModal'].currentValue) {
      setTimeout(() => {
        this.focusTrapService.trapFocus(this.modalContainer.nativeElement);
      }, 0);

      document.addEventListener('keydown', this.handleEscapeKey.bind(this));
    }
  }

  handleEscapeKey(event: KeyboardEvent): void {
    if (event.key === 'Escape' && this.showModal) {
      this.closeStarModal();
    }
  }

  ngOnDestroy() {
    if (this.modalContainer) {
      this.focusTrapService.releaseFocus(this.modalContainer.nativeElement);
    }
    document.removeEventListener('keydown', this.handleEscapeKey.bind(this));
  }

  closeStarModal() {
    this.close.emit();
  }

  onOverlayClick(event: MouseEvent) {
    this.closeStarModal();
  }

  launch(app: AppInfo) {
    this.launchControlService.launchApp(app.appsTag);
  }

  hasApps(appInfos: AppInfo[]) {
    if (appInfos) {
      return appInfos.length > 0;
    }
    return false;
  }
}
