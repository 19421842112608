import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class FocusTrapService {
  private firstFocusableElement: HTMLElement | null = null;
  private lastFocusableElement: HTMLElement | null = null;

  trapFocus(container: HTMLElement): void {
    const focusableSelectors =
      'a, button, textarea, input, select, [tabindex]:not([tabindex="-1"])';
    const focusableElements = container.querySelectorAll<HTMLElement>(focusableSelectors);

    if (focusableElements.length === 0) {
      console.warn('No focusable elements found inside the container.');
      return;
    }

    this.firstFocusableElement = focusableElements[0];
    this.lastFocusableElement = focusableElements[focusableElements.length - 1];

    container.addEventListener('keydown', this.handleKeydown.bind(this));
  }  

  private handleKeydown(event: KeyboardEvent): void {
    if (event.key !== 'Tab') {
      return;
    }

    const focusedElement = document.activeElement;
    if (event.shiftKey && focusedElement === this.firstFocusableElement) {
     event.preventDefault();
      this.lastFocusableElement?.focus();
    } else if (!event.shiftKey && focusedElement === this.lastFocusableElement) {
      event.preventDefault();
      this.firstFocusableElement?.focus();
    }
  }

  releaseFocus(container: HTMLElement): void {
    container.removeEventListener('keydown', this.handleKeydown.bind(this));
    this.firstFocusableElement = null;
    this.lastFocusableElement = null;
  }
}
