import { Component } from '@angular/core';
import { LearningJourneyService } from '../../services/learning-journey/learning-journey.service';
import { SchoolYear } from '../../shared/models/school-year.model';
import { CommonModule } from '@angular/common';
import { AccomplishmentCardConfig } from '../../shared/models/accomplishment-card-config.model';
import { AccomplishmentsGridComponent } from './accomplishments-grid/accomplishments-grid.component';
import { AccomplishmentsService } from '../../services/accomplishments/accomplishments.service';

@Component({
  selector: 'app-all-year',
  standalone: true,
  imports: [CommonModule, AccomplishmentsGridComponent],
  templateUrl: './all-year.component.html',
  styleUrl: './all-year.component.scss'
})
export class AllYearComponent {
  public schoolYear: SchoolYear | undefined = undefined;
  public cardConfigs: AccomplishmentCardConfig[] = [];
  constructor(
    private learningJourneyService: LearningJourneyService,
    private accomplishmentsService: AccomplishmentsService
  ) {
  }

  async ngOnInit() {
    this.cardConfigs = await this.accomplishmentsService.getYearlyAccomplishments();

    try {
      this.schoolYear = await this.learningJourneyService.getCurrentSchoolYear();
    } catch (error) {
      console.error('Error fetching school year:', error);
      this.schoolYear = undefined;
    }
  }
}
