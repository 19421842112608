import { Injectable } from '@angular/core';
import { firstValueFrom, of, take } from 'rxjs';
import { StarTestResponse } from './star-test.response';

@Injectable({
  providedIn: 'root',
})
export class StarTestService {
  constructor() {}

  async getStarReadingTestData(): Promise<StarTestResponse[]> {
    const mockData = [
      {
        testDate: '2024-09-06T00:15:30.123Z',
        scaledScore: 650,
        skills: [
          { shortName: 'Cite evidence when explaining literary text' },
          { shortName: 'Determine directly / indirectly stated themes' },
          { shortName: 'Compare key plot events in a story or drama' },
          { shortName: 'Summarize text using main ideas and key details' },
          { shortName: 'Explain how plot events create structure' },
        ],
      },
      {
        testDate: '2024-12-03T10:15:30.123Z',
        scaledScore: 850,
        skills: [
          { shortName: 'Cite evidence when explaining literary text' },
          { shortName: 'Determine directly / indirectly stated themes' },
          { shortName: 'Compare key plot events in a story or drama' },
          { shortName: 'Summarize text using main ideas and key details' },
          { shortName: 'Explain how plot events create structure' },
        ],
      },
      {
        testDate: '2025-01-06T00:15:30.123Z',
        scaledScore: 600,
        skills: [
          { shortName: 'Cite evidence when explaining literary text' },
          { shortName: 'Determine directly / indirectly stated themes' },
          { shortName: 'Compare key plot events in a story or drama' },
          { shortName: 'Summarize text using main ideas and key details' },
          { shortName: 'Explain how plot events create structure' },
        ],
      },
    ];

    // TODO: replace with actual API call
    let starTests = await firstValueFrom(
      of(mockData).pipe(
        take(1)
      )
    );

    return starTests;
  }

  async getStarMathTestData(): Promise<StarTestResponse[]> {
    const mockData = [
      {
        testDate: '2024-10-06T00:15:30.123Z',
        scaledScore: 650,
        skills: [
          { shortName: 'Cite evidence when explaining literary text' },
          { shortName: 'Determine directly / indirectly stated themes' },
          { shortName: 'Compare key plot events in a story or drama' },
          { shortName: 'Summarize text using main ideas and key details' },
          { shortName: 'Explain how plot events create structure' },
        ],
      },
      {
        testDate: '2024-12-20T10:15:30.123Z',
        scaledScore: 850,
        skills: [
          { shortName: 'Cite evidence when explaining literary text' },
          { shortName: 'Determine directly / indirectly stated themes' },
          { shortName: 'Compare key plot events in a story or drama' },
          { shortName: 'Summarize text using main ideas and key details' },
          { shortName: 'Explain how plot events create structure' },
        ],
      },
      {
        testDate: '2025-01-03T00:15:30.123Z',
        scaledScore: 600,
        skills: [
          { shortName: 'Cite evidence when explaining literary text' },
          { shortName: 'Determine directly / indirectly stated themes' },
          { shortName: 'Compare key plot events in a story or drama' },
          { shortName: 'Summarize text using main ideas and key details' },
          { shortName: 'Explain how plot events create structure' },
        ],
      },
    ];

    // TODO: replace with actual API call
    let starTests = await firstValueFrom(
      of(mockData).pipe(
        take(1)
      )
    );

    return starTests;
  }
}
