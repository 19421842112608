<div class="modal-overlay" *ngIf="showModal" role="dialog" aria-modal="true" aria-labelledby="modal-title"
  (click)="onOverlayClick($event)">
  <div class="modal-parent">
    <img src="../../assets/freckle-corkboard-sticker.svg" alt="" class="freckle-skill-modal-corner-image" />
    <div class="modal" tabindex="-1" #modalContainer>
      <div class="modal-header">
        <h2 class="freckle-skill-modal-section-header"> I practiced <strong>{{freckleSkillResult?.timeSpent}}</strong>
          in
          Freckle.</h2>
        <button class="close-modal-btn" #closeButton (click)="closeFreckleSkillModal()">Close</button>
      </div>
      <div class="modal-body">
        <div class="freckle-skill-modal-box">
          <div class="line"></div>
          <div class="freckle-skill-modal-content-skills">
            <ng-container *ngIf="freckleSkillResult!.mastered!.skills!.length > 0">
              <div class="freckle-skill-modal-header-row">
                <img src="../../assets/ready-for-more.svg" alt="" class="freckle-skill-modal-practise-image" />
                <h3 class="freckle-skill-modal-sub-section-header">{{freckleSkillResult?.mastered?.groupName}}</h3>
              </div>
              <ul class="freckle-skill-modal-bullet">
                <li *ngFor="let skill of freckleSkillResult?.mastered?.skills">{{skill?.displayName}}
                </li>
              </ul>
            </ng-container>
            <ng-container *ngIf="freckleSkillResult!.practicing!.skills!.length > 0">
              <div class="freckle-skill-modal-header-row">
                <img src="../../assets/appropriate-level.svg" alt="" class="freckle-skill-modal-practise-image" />
                <h3 class="freckle-skill-modal-sub-section-header">{{freckleSkillResult?.practicing?.groupName}}</h3>
              </div>
              <ul class="freckle-skill-modal-bullet">
                <li *ngFor="let skill of freckleSkillResult?.practicing?.skills">{{skill?.displayName}}
                </li>
              </ul>
            </ng-container>
            <ng-container *ngIf="freckleSkillResult!.needHelp!.skills!.length > 0">
              <div class="freckle-skill-modal-header-row">
                <img src="../../assets/helping-hand.svg" alt="" class="freckle-skill-modal-practise-image" />
                <h3 class="freckle-skill-modal-sub-section-header">{{freckleSkillResult?.needHelp?.groupName}}</h3>
              </div>
              <ul class="freckle-skill-modal-bullet">
                <li *ngFor="let skill of freckleSkillResult?.needHelp?.skills">{{skill?.displayName}}
                </li>
              </ul>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>