import { Component, Input, OnInit } from '@angular/core';
import { FreckleSkillResult } from '../../models/freckle-skill-result';
import { CommonModule } from '@angular/common';
import { FreckleSkillModalComponent } from '../freckle-skill-modal/freckle-skill-modal.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'freckle-skill',
  standalone: true,
  imports: [CommonModule, FreckleSkillModalComponent],
  templateUrl: './freckle-skill.component.html',
  styleUrl: './freckle-skill.component.scss'
})
export class FreckleSkillComponent {
  showModal: boolean = false;
  launchUrl_FR = environment.launchControlBaseUrl + 'apps_fr';
  @Input() freckleSkillResult: FreckleSkillResult | null = null;
  @Input() subject: "math" | "reading" = "reading";
  subjectMessaging = {
    "reading": " Reading practice you finish will show up here at the end of the week.",
    "math": " Math practice you finish will show up here at the end of the week."
  } as const;
  constructor() { }

  openFreckleSkillModal(event: Event): void {
    event.preventDefault();
    this.showModal = true;
    (event.target as HTMLElement)?.blur();
  }

  closeFreckleSkillModal(): void {
    this.showModal = false;
    (document.querySelector('a.show-all-skills-link') as HTMLElement)?.focus();
  }
}
