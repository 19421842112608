<nav class="timeline-picker-container" aria-label="Month picker">
  <div class="timeline-picker">
    <label for="timeline-select" class="label">
      Month:
    </label>
    <div class="line"></div>
    <div class="picker">
      <select name="timeline" id="timeline-select" class="timeline-select" [(ngModel)]="selectedMonth" (ngModelChange)="onMonthChange()">
        <option *ngFor="let month of timelineMonths" [ngValue]="month">{{month.label}}</option>
      </select>
    </div>
  </div>
</nav>
