import { Routes } from '@angular/router';
import { AppRoutingGuard } from './app-routing.guard';
import { HomeComponent } from './home/home.component';
import { LogOutComponent } from './home/log-out/log-out.component';
import { MainComponent } from './main/main.component';
import { PopUpComponent } from './shared/pop-up/pop-up.component';
import { MyAppsComponent } from './my-apps/my-apps.component';
import { MyLearningComponent } from './my-learning/my-learning.component';
import { ReadingComponent } from './reading/reading.component';
import { MathComponent } from './math/math.component';
import { AllYearComponent } from './my-learning/all-year/all-year.component';

export const routes: Routes = [
    { path: 'mylearningjourney', redirectTo: 'mylearningjourney/reading', pathMatch: 'full' },
    { path: '', redirectTo: 'myapps', pathMatch: 'full' },
    {
        path: '', component: MainComponent, canActivate: [AppRoutingGuard],
        children: [
            { path: 'myapps', component: MyAppsComponent, canActivate: [AppRoutingGuard] },
            {
                path: 'mylearningjourney', component: MyLearningComponent, canActivate: [AppRoutingGuard],
                children: [
                    { path: 'reading', component: ReadingComponent, canActivate: [AppRoutingGuard] },
                    { path: 'math', component: MathComponent, canActivate: [AppRoutingGuard] },
                    { path: 'all-year', component: AllYearComponent, canActivate: [AppRoutingGuard] }
                ]
            }]
    },
    { path: 'logout', component: LogOutComponent, canActivate: [AppRoutingGuard] },
    { path: '**', redirectTo: 'myapps' }
];
