<!-- REVISIT: add aria region? -->
<div class="card-container">
    <div class="card-content">
        <div class="card-image">
            <img src="../../../assets/trophy-placeholder.svg" alt="Placeholder image"
                class="accomplishment-image" role="img" aria-label="A trophy" />
        </div>
        <div class="card-description">
          <h3 class="card-title">{{this.cardConfig.title}}</h3>
          <div *ngIf="this.cardConfig.subtitle" class="card-subtitle">{{this.cardConfig.subtitle}}</div>
        </div>
        <div class="footer">
          <img [src]="this.cardConfig.svgUrl" alt="" class="icon" />
          <div class="label">In {{this.cardConfig.app}}</div>
        </div>
    </div>
</div>
