import { Inject, Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';

export interface LaunchOptions {
    deepLink?: string;
    isBlankTarget?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LaunchControlService {
  constructor(
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  public launchApp(appCode: string, options?: LaunchOptions) {
    let homeUri = this.userService.getHomeUri();
    const {isBlankTarget, deepLink} = {...options};
    const launchUrl = environment.launchControlBaseUrl + appCode;
    const form = this.document.createElement('form');
    form.method = 'POST';
    form.action = launchUrl;
    if (isBlankTarget) {
      form.target = '_blank';
    }

    const homeUriField = this.document.createElement('input');
    homeUriField.type = 'hidden';
    homeUriField.name = "homeURI";
    homeUriField.value = homeUri;
    form.appendChild(homeUriField);

    if (deepLink) {
      const deepLinkField = this.document.createElement('input');
      deepLinkField.type = 'hidden';
      deepLinkField.name = 'deepLink';
      if (typeof deepLink === 'string') {
        deepLinkField.value = encodeURIComponent(deepLink);
      } else {
        try {
          deepLinkField.value = JSON.stringify(deepLink);
        } catch (e) {
          console.error('Unable to encode launch deepLink, possibly circular object?', e);
        }
      }
      form.appendChild(deepLinkField);
    }

    this.document.body.appendChild(form);
    form.submit();
  }
}