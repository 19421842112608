import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FreckleDataService } from '../services/freckle-data/freckledata.service';
import { FreckleSkillComponent } from '../shared/components/freckle-skill/freckle-skill.component';
import { StarTestComponent } from "../shared/components/star-test/star-test.component";
import { FreckleSkillService } from '../services/freckle-skill/freckle-skill.service';
import { FreckleSkill } from '../shared/models/freckle-skill';
import { FreckleSkillResult } from '../shared/models/freckle-skill-result';
import { StarTestService } from '../services/freckle-skill/star-test.service';
import { StarTestResponse } from '../services/freckle-skill/star-test.response';
import { ArTestComponent } from '../shared/components/ar-test/ar-test.component';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'reading',
  standalone: true,
  imports: [CommonModule, FreckleSkillComponent, StarTestComponent, ArTestComponent],
  templateUrl: './reading.component.html',
  styleUrl: './reading.component.scss'
})
export class ReadingComponent implements OnInit {

  monthPhrases: any[] = [
    { id: 1, monthPhraseIcon: 'jan-reading.svg' },
    { id: 2, monthPhraseIcon: 'feb-reading.svg' },
    { id: 3, monthPhraseIcon: 'mar-reading.svg' },
    { id: 4, monthPhraseIcon: 'apr-reading.svg' },
    { id: 5, monthPhraseIcon: 'may-reading.svg' },
    { id: 6, monthPhraseIcon: 'jun-reading.svg' },
    { id: 7, monthPhraseIcon: 'jul-reading.svg' },
    { id: 8, monthPhraseIcon: 'aug-reading.svg' },
    { id: 9, monthPhraseIcon: 'sep-reading.svg' },
    { id: 10, monthPhraseIcon: 'oct-reading.svg' },
    { id: 11, monthPhraseIcon: 'nov-reading.svg' },
    { id: 12, monthPhraseIcon: 'dec-reading.svg' }
  ]

  selectedMonth: number = 9;
  selectedMonthPhrase: string = 'jan-reading.svg';

  FreckleDataService = inject(FreckleDataService);
  starTestService = inject(StarTestService);

  containerClass: string = '';
  componentContainerClass: string = '';
  arComponentContainerClass: string = '';
  starTestData: StarTestResponse[] = [];
  starHeaderText: string = "I took a Star Reading test on";
  data: string[] = [];
  showModal: boolean = false;
  freckleReadingSkills: FreckleSkill[] = [];
  freckleSkillResult: FreckleSkillResult | null = null;
  loading = false;

  constructor(private readingSkillService: FreckleSkillService, private route: ActivatedRoute) { }
  async ngOnInit() {
    this.freckleReadingSkills = await this.readingSkillService.getReadingSkills();
    this.starTestData = await this.starTestService.getStarReadingTestData();

    //Added comments to test zero state
    //this.starTestData = [];
    //this.freckleReadingSkills = [];
    this.processFreckleSkills();
    this.setContainerClass();
    this.FreckleDataService.getEmptyMockData().subscribe((response) => {
      this.data = response;
    });

    this.route.queryParams.subscribe(params => {
      this.selectedMonth = params['month'];  // To access a specific query param
      this.selectMonthPhrase();
    });
  }
  processFreckleSkills() {
    if (this.freckleReadingSkills.length > 0) {
      this.freckleSkillResult = this.readingSkillService.processFreckleSkills(this.freckleReadingSkills);
    }
  }

  setContainerClass() {
    if (this.starTestData.length === 0) {
      this.containerClass = 'reading-container-zero-state';
      this.componentContainerClass = 'component-container-zero-state';
      this.arComponentContainerClass = 'ar-component-container-zero-state'
    }
    else {
      this.containerClass = 'reading-container';
      this.componentContainerClass = 'component-container';
      this.arComponentContainerClass = 'ar-component-container'
    }
  }

  selectMonthPhrase() {
    let month = this.monthPhrases.find(m => m.id == this.selectedMonth);
    this.selectedMonthPhrase = month?.monthPhraseIcon;
  }
}


